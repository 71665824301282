import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/index";
import Layout from "./components/Layout/Layout";
import AboutUs from "./pages/AboutUs";
import ITStaffResources from "./pages/ITStaffResources";
import EventPage from "./pages/events";
import EnterprisePage from "./pages/EnterprisePage";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/talent" element={<ITStaffResources />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/enterprise" element={<EnterprisePage />} />

          {/* Add additional routes here as needed */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
