import React from "react";

const TabNavigation = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className="flex space-x-4 justify-center mt-8 mb-12">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`px-6 py-2 rounded-md font-medium ${
            activeTab === tab ? "bg-blue-600 text-white" : "bg-gray-100"
          } hover:bg-blue-500 hover:text-white transition`}
          onClick={() => onTabChange(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabNavigation;
