import React from "react";
import { FaHeartbeat, FaHandHoldingUsd, FaGasPump, FaPoll } from "react-icons/fa";

const industries = [
  {
    title: "Health Care & Insurance Service Solution",
    icon: <FaHeartbeat size={40} className="text-blue-500" />,
  },
  {
    title: "Government Grants Management Solution",
    icon: <FaHandHoldingUsd size={40} className="text-blue-500" />,
  },
  {
    title: "Oil and Natural Gas Solution",
    icon: <FaGasPump size={40} className="text-blue-500" />,
  },
  {
    title: "Political Campaign Management & Analysis",
    icon: <FaPoll size={40} className="text-blue-500" />,
  },
];

const IndustriesGrid = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold mb-12">Industries</h2>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center border border-blue-500 rounded-lg p-6 hover:shadow-lg transition duration-300"
            >
              {/* Icon */}
              <div className="mb-4">{industry.icon}</div>
              {/* Title */}
              <h3 className="text-lg font-bold text-gray-800">{industry.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IndustriesGrid;
