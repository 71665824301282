import React, { useState } from "react";
import HeroSection from "../components/enterprise/HeroSection";
import TabNavigation from "../components/enterprise/TabNavigation";
import WorkProcess from "../components/enterprise/WorkProcess";
import ContactFormSection from "../components/enterprise/ContactFormSection";
import WorkSteps from "../components/enterprise/WorkProcess";

const EnterprisePage = () => {
  const [activeTab, setActiveTab] = useState("Software Development");

  const tabs = [
    "Software Development",
    "Upskilling",
    "Train to Hire",
    "Staff Augmentation",
  ];

  const workSteps = {
    "Software Development": [
      {
        title: "Client Discussion",
        description:
          "Understanding needs, discussing solutions, and aligning project goals.",
      },
      {
        title: "Planning and Analysis",
        description:
          "Setting objectives, gathering requirements, and creating a project plan.",
      },
      {
        title: "Design and Development",
        description:
          "Designing architecture, developing, and testing solutions.",
      },
      {
        title: "Release & Deployment",
        description: "Launching applications and ensuring their availability.",
      },
      {
        title: "Post Deployment Support",
        description: "Monitoring, bug fixes, and ongoing updates.",
      },
    ],
    Upskilling: [
      {
        title: "Skill Assessment",
        description: "Identifying skill gaps and training requirements.",
      },
      {
        title: "Custom Training Design",
        description: "Developing tailored training programs.",
      },
      {
        title: "Hands-On Training",
        description: "Interactive sessions to ensure practical learning.",
      },
      {
        title: "Skill Validation",
        description: "Assessing trainees’ skills post-training.",
      },
      {
        title: "Continuous Support",
        description: "Providing resources and support for ongoing learning.",
      },
    ],
    "Train to Hire": [
      {
        title: "Candidate Selection",
        description: "Shortlisting candidates for targeted training.",
      },
      {
        title: "Tailored Training Programs",
        description:
          "Preparing candidates with necessary skills for specific roles.",
      },
      {
        title: "Skill Validation",
        description:
          "Evaluating candidate readiness for real-world challenges.",
      },
      {
        title: "Onboarding Support",
        description: "Assisting with a smooth transition into the workforce.",
      },
      {
        title: "Performance Monitoring",
        description: "Ensuring candidates meet organizational expectations.",
      },
    ],
    "Staff Augmentation": [
      {
        title: "Requirement Analysis",
        description: "Identifying talent needs and project goals.",
      },
      {
        title: "Resource Selection",
        description: "Shortlisting and providing skilled professionals.",
      },
      {
        title: "Seamless Integration",
        description: "Integrating resources into the existing team.",
      },
      {
        title: "Project Execution",
        description: "Ensuring smooth collaboration and task execution.",
      },
      {
        title: "Scalability Support",
        description: "Providing additional resources as the project evolves.",
      },
    ],
  };

  const heroContent = {
    "Software Development": {
      title: "Software Development and Consulting",
      description:
        "We offer custom software solutions tailored to meet specific business needs.",
      img: "https://images.unsplash.com/photo-1531498860502-7c67cf02f657?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Software Development Image
    },
    Upskilling: {
      title: "Upskilling Services",
      description:
        "Equip your team with the latest tech skills to drive growth.",
      img: "https://images.unsplash.com/photo-1637855195094-992d3d578f42?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Upskilling Image
    },
    "Train to Hire": {
      title: "Train to Hire Programs",
      description:
        "Prepare talent for your organization through targeted training.",
      img: "https://images.unsplash.com/photo-1582004531564-50f300aae039?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Training Image
    },
    "Staff Augmentation": {
      title: "Staff Augmentation Services",
      description:
        "Bridge the talent gap with skilled professionals on demand.",
      img: "https://images.unsplash.com/photo-1576267423429-569309b31e84?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Staff Augmentation Image
    },
  };

  return (
    <div className="mt-20">
      <div className="container mx-auto px-4 sm:px-6 py-12">
      <ContactFormSection />

      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <HeroSection
        title={heroContent[activeTab].title}
        description={heroContent[activeTab].description}
        img={heroContent[activeTab].img}
      />
      <WorkSteps steps={workSteps[activeTab]} />
    </div>
    </div>
    
  );
};

export default EnterprisePage;
