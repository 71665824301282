import React from "react";

const EventCard = ({ event, view }) => {
  return (
    <div
      className={`p-4 border rounded-lg ${
        view === "list" ? "flex items-center space-x-4" : "text-center"
      }`}
    >
      <img
        src={event.image}
        alt={event.title}
        className={`${view === "list" ? "w-24 h-24" : "w-full h-40"} object-cover rounded`}
      />
      <div>
        <h3 className="text-lg font-bold">{event.title}</h3>
        <p className="text-sm text-gray-600">{event.description}</p>
        <div className="flex space-x-2 mt-2">
          <span className="text-xs bg-blue-100 text-blue-600 px-2 py-1 rounded">
            {event.category}
          </span>
          <span className="text-xs bg-green-100 text-green-600 px-2 py-1 rounded">
            {event.type}
          </span>
        </div>
        <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
          Access Replay
        </button>
      </div>
    </div>
  );
};

export default EventCard;
