import React from "react";
import { FaTh, FaList } from "react-icons/fa";

const ViewToggle = ({ view, onViewChange }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 items-center justify-center">
      <button
        className={`flex items-center justify-center space-x-2 w-full sm:w-auto px-3 py-2 rounded-lg text-sm shadow-md ${
          view === "grid"
            ? "bg-blue-500 text-white"
            : "bg-gray-200 hover:bg-gray-300"
        } transition duration-300`}
        onClick={() => onViewChange("grid")}
      >
        <FaTh className="text-base" />
        <span className="hidden sm:inline">Grid View</span>
      </button>
      <button
        className={`flex items-center justify-center space-x-2 w-full sm:w-auto px-3 py-2 rounded-lg text-sm shadow-md ${
          view === "list"
            ? "bg-blue-500 text-white"
            : "bg-gray-200 hover:bg-gray-300"
        } transition duration-300`}
        onClick={() => onViewChange("list")}
      >
        <FaList className="text-base" />
        <span className="hidden sm:inline">List View</span>
      </button>
    </div>
  );
};

export default ViewToggle;
