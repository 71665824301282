import React from "react";

const WorkSteps = ({ steps }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-10">
      {steps.map((step, index) => (
        <div key={index} className="text-center">
          <div className="bg-blue-500 text-white w-12 h-12 mx-auto rounded-xl flex items-center justify-center mb-4 font-bold text-lg">
            {index + 1}
          </div>
          <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
          <p className="text-gray-600">{step.description}</p>
        </div>
      ))}
    </div>
  );
};

export default WorkSteps;
