import React from "react";

const ContactFormSection = () => {
  return (
    <div className="bg-blue-600 my-10 text-white py-16 px-8 flex justify-center items-center">
      <div className="w-1/2">
        <h2 className="text-3xl font-bold mb-6">Be a part of our journey</h2>
        <p className="text-lg mb-6">
          Whether you are switching to a new tech stack or need to upskill your team, we've got you covered.
        </p>
      </div>
      <div className="bg-white text-black rounded-lg p-6 shadow-lg w-1/2">
        <form className="space-y-4">
          <div className="flex space-x-4">
            <input
              type="text"
              placeholder="First Name"
              className="border p-2 rounded-lg w-1/2"
            />
            <input
              type="text"
              placeholder="Last Name"
              className="border p-2 rounded-lg w-1/2"
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            className="border p-2 rounded-lg w-full"
          />
          <input
            type="text"
            placeholder="Subject"
            className="border p-2 rounded-lg w-full"
          />
          <textarea
            placeholder="Your Query?"
            className="border p-2 rounded-lg w-full"
            rows="4"
          ></textarea>
          <div>
            <input type="checkbox" id="terms" />
            <label htmlFor="terms" className="text-sm ml-2">
              I agree to the <a href="#" className="text-blue-500">terms and conditions</a>
            </label>
          </div>
          <button className="bg-blue-600 text-white px-6 py-2 rounded-md">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactFormSection;
