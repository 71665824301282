import React from "react";

const HeroSection = () => {
  return (
    <div className="relative  flex items-start justify-between">
      <div className="flex w-full">
        {/* Left Section */}
        <div
          className="bg-blue-500 text-white flex flex-col justify-center items-start w-1/2 pl-4 md:pl-10 pb-40"
          style={{ fontFamily: "'Poppins', sans-serif" }} // Font optimized to Poppins
        >
          <h1 className="text-xl md:text-4xl font-bold leading-tight mb-4">
            Rapid Software Solutions For Your Business
          </h1>
          <p className="text-sm md:text-lg">
            Solving Your Data Problems with Great Technology
          </p>
        </div>

        {/* Right Section */}
        <div className="relative w-1/2 py-28 bg-blue-500">
          <div className="relative w-full h-ful">
            <img
              alt="Banner Image"
              loading="lazy"
              className="h-full object-cover rounded-lg  p-5"
              src="./Home-1.jpg"
            />
            <div className="absolute top-0 left-0 w-full h-full opacity-50 rounded-lg"></div>
          </div>
        </div>
      </div>

      {/* Statistics Section */}
      <section
        className="p-4 sm:p-6 md:p-8 absolute bottom-0 left-1/2 transform -translate-x-1/2 w-72 md:w-[700px] lg:w-[1000px] rounded-t-lg backdrop-blur-md bg-white/30"
      >
        <div
          className="grid grid-cols-3 gap-8 md:gap-16 text-center"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <div>
            <h2 className="text-lg md:text-3xl lg:text-5xl font-bold text-gray-800">
              90+
            </h2>
            <p className="text-xs md:text-sm lg:text-lg text-gray-700">
              Happy Clients
            </p>
          </div>
          <div>
            <h2 className="text-lg md:text-3xl lg:text-5xl font-bold text-gray-800">
              150+
            </h2>
            <p className="text-xs md:text-sm lg:text-lg text-gray-700">
              Works Done
            </p>
          </div>
          <div>
            <h2 className="text-lg md:text-3xl lg:text-5xl font-bold text-gray-800">
              10
            </h2>
            <p className="text-xs md:text-sm lg:text-lg text-gray-700">
              Years in Business
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
