import React, { useRef, useState } from "react";

// Sample staff data with randomuser.me images
const staffData = [
  {
    id: 1,
    name: "John Doe",
    skill: "Frontend Development",
    image: "https://randomuser.me/api/portraits/men/11.jpg",
    description: "Specializes in React, Angular, and Vue.js.",
  },
  {
    id: 2,
    name: "Jane Smith",
    skill: "Backend Development",
    image: "https://randomuser.me/api/portraits/women/12.jpg",
    description: "Expert in Node.js, Django, and Spring Boot.",
  },
  {
    id: 3,
    name: "Sam Wilson",
    skill: "Cloud Engineer",
    image: "https://randomuser.me/api/portraits/men/15.jpg",
    description: "Experienced in AWS, Azure, and Google Cloud.",
  },
  {
    id: 4,
    name: "Emily Brown",
    skill: "Data Scientist",
    image: "https://randomuser.me/api/portraits/women/16.jpg",
    description: "Proficient in Python, R, and Machine Learning.",
  },
  {
    id: 5,
    name: "Michael Green",
    skill: "DevOps Engineer",
    image: "https://randomuser.me/api/portraits/men/19.jpg",
    description: "Expert in CI/CD, Kubernetes, and Jenkins.",
  },
  {
    id: 6,
    name: "Sophia White",
    skill: "UI/UX Designer",
    image: "https://randomuser.me/api/portraits/women/20.jpg",
    description: "Specializes in Figma, Sketch, and Adobe XD.",
  },
  {
    id: 7,
    name: "Chris Martin",
    skill: "Mobile App Developer",
    image: "https://randomuser.me/api/portraits/men/21.jpg",
    description: "Expert in Flutter, React Native, and Kotlin.",
  },
  {
    id: 8,
    name: "Olivia Johnson",
    skill: "Product Manager",
    image: "https://randomuser.me/api/portraits/women/22.jpg",
    description: "Proficient in Agile, Scrum, and Kanban methodologies.",
  },
  {
    id: 9,
    name: "Liam Garcia",
    skill: "Cybersecurity Specialist",
    image: "https://randomuser.me/api/portraits/men/23.jpg",
    description: "Specializes in network security and ethical hacking.",
  },
  {
    id: 10,
    name: "Amelia Davis",
    skill: "AI Engineer",
    image: "https://randomuser.me/api/portraits/women/24.jpg",
    description: "Experienced in machine learning and natural language processing.",
  },
  {
    id: 11,
    name: "Ethan Thomas",
    skill: "Blockchain Developer",
    image: "https://randomuser.me/api/portraits/men/25.jpg",
    description: "Specializes in Solidity, Ethereum, and smart contracts.",
  },
  {
    id: 12,
    name: "Isabella Wilson",
    skill: "Data Engineer",
    image: "https://randomuser.me/api/portraits/women/26.jpg",
    description: "Expert in ETL pipelines, Big Data, and Spark.",
  },
  {
    id: 13,
    name: "Mason Moore",
    skill: "Game Developer",
    image: "https://randomuser.me/api/portraits/men/27.jpg",
    description: "Experienced in Unity, Unreal Engine, and C#.",
  },
  {
    id: 14,
    name: "Ava Taylor",
    skill: "Technical Writer",
    image: "https://randomuser.me/api/portraits/women/28.jpg",
    description: "Proficient in creating user manuals and API documentation.",
  },
  {
    id: 15,
    name: "Noah Anderson",
    skill: "QA Engineer",
    image: "https://randomuser.me/api/portraits/men/29.jpg",
    description: "Specializes in automated testing using Selenium and Cypress.",
  },
  {
    id: 16,
    name: "Sophia Harris",
    skill: "Digital Marketer",
    image: "https://randomuser.me/api/portraits/women/30.jpg",
    description: "Expert in SEO, SEM, and social media marketing.",
  },
  {
    id: 17,
    name: "James Clark",
    skill: "AR/VR Developer",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    description: "Specializes in augmented and virtual reality applications.",
  },
  {
    id: 18,
    name: "Mia Lewis",
    skill: "System Administrator",
    image: "https://randomuser.me/api/portraits/women/32.jpg",
    description: "Expert in managing servers and Linux systems.",
  },
  {
    id: 19,
    name: "Lucas Martinez",
    skill: "Database Administrator",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    description: "Specializes in SQL, NoSQL, and database optimization.",
  },
  {
    id: 20,
    name: "Ella Robinson",
    skill: "Technical Support Engineer",
    image: "https://randomuser.me/api/portraits/women/34.jpg",
    description: "Experienced in troubleshooting and customer support.",
  },
  {
    id: 21,
    name: "Jack Walker",
    skill: "AI Researcher",
    image: "https://randomuser.me/api/portraits/men/35.jpg",
    description: "Focuses on advanced AI algorithms and deep learning.",
  },
  {
    id: 22,
    name: "Charlotte Hall",
    skill: "E-commerce Specialist",
    image: "https://randomuser.me/api/portraits/women/36.jpg",
    description: "Proficient in Shopify, Magento, and WooCommerce.",
  },
  {
    id: 23,
    name: "Henry Young",
    skill: "Network Engineer",
    image: "https://randomuser.me/api/portraits/men/37.jpg",
    description: "Expert in network architecture and troubleshooting.",
  },
  {
    id: 24,
    name: "Emily Scott",
    skill: "Business Analyst",
    image: "https://randomuser.me/api/portraits/women/38.jpg",
    description: "Skilled in gathering and analyzing business requirements.",
  },
  {
    id: 25,
    name: "Daniel Baker",
    skill: "IoT Specialist",
    image: "https://randomuser.me/api/portraits/men/39.jpg",
    description: "Experienced in Internet of Things development.",
  },
  {
    id: 26,
    name: "Grace Martinez",
    skill: "Full-Stack Developer",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    description: "Proficient in both frontend and backend development.",
  },
];


// Tabs data
const tabs = [
  "All",
  "Frontend Development",
  "Backend Development",
  "Cloud Engineer",
  "Data Scientist",
  "DevOps Engineer",
  "UI/UX Designer",
  "Mobile App Developer",
  "Product Manager",
  "Cybersecurity Specialist",
  "AI Engineer",
  "Blockchain Developer",
  "Data Engineer",
  "Game Developer",
  "Technical Writer",
  "QA Engineer",
  "Digital Marketer",
  "AR/VR Developer",
  "System Administrator",
  "Database Administrator",
  "Technical Support Engineer",
  "AI Researcher",
  "E-commerce Specialist",
  "Network Engineer",
  "Business Analyst",
  "IoT Specialist",
  "Full-Stack Developer",
];



const ITStaffResources = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [visibleStaffCount, setVisibleStaffCount] = useState(12); // Initial visible count
  const [isLoading, setIsLoading] = useState(false);
  const staffGridRef = useRef(null);

  // Filter staff data based on the active tab
  const filteredStaff =
    activeTab === "All"
      ? staffData
      : staffData.filter((staff) => staff.skill === activeTab);

  // Handle tab click and scroll
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setVisibleStaffCount(12); // Reset visible count when switching tabs
    staffGridRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Handle Load More
  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleStaffCount((prev) => prev + 12);
      setIsLoading(false);
    }, 1000); // Simulate a 1-second loading delay
  };

  return (
    <div className="mt-20">
      <div className="container mx-auto px-4 sm:px-6 py-12">
        {/* Page Header */}
        <div className="text-center mb-8 px-4">
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">IT Staff Resources</h1>
          <p className="text-gray-600 text-sm sm:text-lg">
            Find the best talent for your IT needs. Browse through our skilled
            professionals below.
          </p>
        </div>

        {/* Tabs for filtering */}
        <div className="flex flex-wrap justify-center gap-2 sm:gap-4 mb-8 sm:mb-12">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`px-4 sm:px-6 py-2 rounded-lg font-medium text-sm sm:text-base ${
                activeTab === tab
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-800"
              } hover:bg-blue-400 hover:text-white transition duration-300`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Staff Grid */}
        <div
          ref={staffGridRef}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
        >
          {filteredStaff.slice(0, visibleStaffCount).map((staff) => (
            <div
              key={staff.id}
              className="bg-blue-50 rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center text-center transform transition duration-300 hover:scale-105"
            >
              <img
                src={staff.image}
                alt={staff.name}
                className="rounded-full w-24 h-24 sm:w-32 sm:h-32 mb-4"
              />
              <h3 className="text-lg sm:text-xl font-bold mb-2">{staff.name}</h3>
              <p className="text-blue-500 font-medium mb-4 text-sm sm:text-base">
                {staff.skill}
              </p>
              <p className="text-gray-600 text-sm sm:text-base">{staff.description}</p>
              <button className="mt-4 px-4 py-2 bg-blue-500 text-white text-sm sm:text-base rounded-md hover:bg-blue-600 transition duration-300">
                More Info
              </button>
            </div>
          ))}
        </div>

        {/* Load More Button */}
        {visibleStaffCount < filteredStaff.length && (
          <div className="flex justify-center mt-8">
            <button
              onClick={handleLoadMore}
              className="px-6 py-3 bg-blue-500 text-white font-medium text-sm sm:text-base rounded-lg hover:bg-blue-600 transition duration-300 flex items-center gap-2"
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ITStaffResources;

