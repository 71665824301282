import React from "react";
import HeroSection from "../components/HeroSection";
import FeatureCards from "../components/FeatureCards";
import FeaturesGridWithImage from "../components/FeaturesGrid";
import ServicesSection from "../components/ServicesSection";
import IndustriesGrid from "../components/IndustriesGrid";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <FeatureCards />
      <FeaturesGridWithImage />
      <ServicesSection />
      <IndustriesGrid />
    </>
  );
};

export default HomePage;
