import React from "react";

const HeroSection = ({ title, description, img }) => {
  return (
    <div className="bg-white text-black py-16 px-6 rounded-lg flex flex-col lg:flex-row justify-between items-center">
      <div className="lg:w-1/2">
        <h1 className="text-4xl font-bold mb-4">{title}</h1>
        <p className="text-lg">{description}</p>
      </div>
      <div className="lg:w-1/2 mt-8 lg:mt-0">
        <img
          src={img}
          alt={title}
          className="w-full h-auto rounded-lg shadow-md"
        />
      </div>
    </div>
  );
};

export default HeroSection;
