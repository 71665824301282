import React from "react";

const features = [
  {
    title: "Custom Software",
    description: "Our software solutions meet your unique business needs.",
    link: "#",
  },
  {
    title: "Data Capturing",
    description:
      "Our data capturing solutions efficiently collect and manage information.",
    link: "#",
  },
  {
    title: "Web & App",
    description:
      "Our customized software solutions are tailored to your business needs.",
    link: "#",
  },
  {
    title: "Off shell packages",
    description:
      "Get up and running quickly with one of our off-the-shelf systems.",
    link: "#",
  },
];

const FeatureCards = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="border border-blue-500 rounded-lg p-6 text-center hover:shadow-md transition duration-300"
            >
              <h3 className="text-lg font-bold text-blue-600 mb-4">
                {feature.title}
              </h3>
              <p className="text-gray-700 mb-6">{feature.description}</p>
              <a
                href={feature.link}
                className="bg-blue-500 text-white py-2 px-4 rounded-full text-sm font-semibold hover:bg-blue-500 transition duration-300"
              >
                Learn more →
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureCards;
