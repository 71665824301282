import React from "react";
import { FaChartBar, FaSearch, FaChartLine, FaClipboardList } from "react-icons/fa";

const features = [
  {
    icon: <FaChartBar size={40} className="text-blue-600" />,
    title: "Dashboard",
    description: "Vital business info curated and displayed in a digestible format.",
  },
  {
    icon: <FaSearch size={40} className="text-blue-600" />,
    title: "Live Monitoring",
    description: "Knows what's happening at your key business points in real time.",
  },
  {
    icon: <FaChartLine size={40} className="text-blue-600" />,
    title: "Sales Analysis",
    description: "Know your business with reports to get key business metrics.",
  },
  {
    icon: <FaClipboardList size={40} className="text-blue-600" />,
    title: "Product Matrix",
    description:
      "Setting complex product structures perfect for clothing and shoe stores.",
  },
];

const FeaturesGridWithImage = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        {/* Left Side Image */}
        <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
          <img
            src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Analytics"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Right Side Grid */}
        <div className="lg:w-1/2 w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-6 border border-gray-200 rounded-lg hover:shadow-lg transition duration-300"
            >
              {/* Icon */}
              <div className="mb-4">{feature.icon}</div>
              {/* Title */}
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                {feature.title}
              </h3>
              {/* Description */}
              <p className="text-sm text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesGridWithImage;
