import React from "react";
import Teams from "../components/Team";
import { FaEye, FaRocket } from "react-icons/fa";
import CustomSoftwareServices from "../components/CustomSoftwareServices";

const AboutUs = () => {
  return (
    <div className="bg-gray-50 text-gray-800">
      {/* Hero Section */}
      <section className="relative">
        <img
          src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="About Us"
          className="w-full h-96 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-6xl font-bold">
            About Us
          </h1>
        </div>
      </section>

      {/* Company Overview */}
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-6 sm:gap-8">
        {/* Image Section */}
        <div className="flex justify-center">
          <img
            src="https://images.unsplash.com/photo-1533417457911-4ec911485388?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Who We Are"
            className="rounded-lg shadow-lg object-cover w-full max-w-md sm:max-w-lg md:max-w-full"
          />
        </div>

        {/* Text Section */}
        <div className="text-center md:text-left">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-600">
            Who We Are
          </h2>
          <p className="text-base sm:text-lg leading-relaxed text-gray-700 mb-4">
            Welcome to <span className="font-semibold">Blueblockss</span>, where
            innovation meets dedication. We are a team of passionate
            professionals committed to delivering exceptional software solutions
            tailored to meet your business needs. Our journey began with a
            mission to revolutionize technology and help businesses thrive in a
            competitive world.
          </p>
          <p className="text-base sm:text-lg leading-relaxed text-gray-700">
            By leveraging state-of-the-art tools and methodologies, we ensure
            every solution we create is robust, scalable, and future-ready.
            Partner with us to transform challenges into opportunities and ideas
            into innovation.
          </p>
        </div>
      </section>

      {/* Mission and Vision */}
      <section className="bg-gradient-to-r from-blue-50 to-blue-100 py-16">
        <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Mission Section */}
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <div className="mb-6 p-4 bg-white rounded-full shadow-md">
              <FaRocket size={48} className="text-blue-600" />
            </div>
            <h3 className="text-3xl font-extrabold text-blue-800 mb-4">
              Our Mission
            </h3>
            <p className="text-gray-700 leading-relaxed text-lg">
              To empower businesses with cutting-edge technology, ensuring they
              remain at the forefront of innovation while achieving sustainable
              growth. At Blueblockss, we believe in building solutions that make
              an impact.
            </p>
          </div>

          {/* Vision Section */}
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <div className="mb-6 p-4 bg-white rounded-full shadow-md">
              <FaEye size={48} className="text-blue-600" />
            </div>
            <h3 className="text-3xl font-extrabold text-blue-800 mb-4">
              Our Vision
            </h3>
            <p className="text-gray-700 leading-relaxed text-lg">
              To become a global leader in software development, transforming
              ideas into reality with creativity, expertise, and precision. Our
              vision is to redefine possibilities in technology for a better
              tomorrow.
            </p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <Teams />
      <CustomSoftwareServices />
      {/* Call to Action */}
      <section className="bg-blue-500 text-white py-12 text-center">
        <h2 className="text-3xl font-bold mb-4">Join Us on Our Journey</h2>
        <p className="text-lg leading-relaxed mb-6">
          Discover the power of innovation and let's create something
          extraordinary together. Reach out to us today to learn more about our
          services and solutions.
        </p>
        <button className="bg-white text-blue-500 px-6 py-3 rounded-md font-bold hover:bg-gray-100 transition duration-300">
          Contact Us
        </button>
      </section>
    </div>
  );
};

export default AboutUs;
