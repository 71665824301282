import React from "react";
import Header from "../Header";
import ContactUs from "../ContactUs";


const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main className="mt-0">{children}</main>
      <footer>
        <ContactUs />
      </footer>
    </div>
  );
};

export default Layout;
