import React from "react";
import { FaLightbulb, FaRocket, FaCloud, FaHandsHelping, FaTasks, FaFileAlt } from "react-icons/fa";

const services = [
  {
    icon: <FaLightbulb size={40} className="text-blue-500" />,
    title: "Software Development",
    description:
      "Tailored software development enables you to implement your requirements and visions through forward-looking technologies, all at a remarkably competitive cost.",
  },
  {
    icon: <FaRocket size={40} className="text-blue-500" />,
    title: "Product Development",
    description:
      "Custom product development helps every business to attain defined goals within timeframe using reduced IT resources and are cost-efficient.",
  },
  {
    icon: <FaCloud size={40} className="text-blue-500" />,
    title: "Cloud Consulting",
    description:
      "Cloud consulting services help organizations to transform IT infrastructure with best-in-class and tailor-made cloud solutions.",
  },
  {
    icon: <FaHandsHelping size={40} className="text-blue-500" />,
    title: "Product Support",
    description:
      "The production support and maintenance service helps your business to stay hassle-free on maintenance and support of applications.",
  },
  {
    icon: <FaTasks size={40} className="text-blue-500" />,
    title: "Modernise Legacy Application",
    description:
      "Businesses modernize outdated programs into cloud-native apps to leverage cloud computing benefits like faster time to market, scalability, agility, and cost savings.",
  },
  {
    icon: <FaFileAlt size={40} className="text-blue-500" />,
    title: "Resource Augmentation",
    description:
      "Resource augmentation allows organizations to immediately boost their scalability, rapid programmability, and efficiency of project delivery.",
  },
];

const CustomSoftwareServices = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-6">
        {/* Header */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">CUSTOM SOFTWARE SERVICES</h2>
          <p className="text-gray-600 text-lg leading-relaxed">
            At Blueblockss, we leverage cutting-edge technologies and disruptive strategies to empower your business. Our commitment lies in delivering smart and innovative software services that propel your success. We’d love the opportunity to discuss how our expertise can contribute to your goals.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="p-6 bg-blue-50 rounded-lg shadow-lg flex flex-col items-center text-center"
            >
              <div className="mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomSoftwareServices;
