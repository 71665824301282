import React from "react";

const EventFilter = ({ topics, languages, activeFilters, onFilterChange }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 space-y-4 sm:space-y-0 mb-6">
      <select
        className="border border-gray-300 rounded-lg px-4 py-2 text-sm shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
        value={activeFilters.topic}
        onChange={(e) => onFilterChange("topic", e.target.value)}
      >
        <option value="All">All Topics</option>
        {topics.map((topic) => (
          <option key={topic} value={topic}>
            {topic}
          </option>
        ))}
      </select>
      <select
        className="border border-gray-300 rounded-lg px-4 py-2 text-sm shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
        value={activeFilters.language}
        onChange={(e) => onFilterChange("language", e.target.value)}
      >
        <option value="All">All Languages</option>
        {languages.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EventFilter;
