import React, { useState, useEffect } from "react";
import EventFilter from "../components/events/FilterSidebar";
import ViewToggle from "../components/events/ViewToggle";
import CategoryTabs from "../components/events/CategoryTabs";
import EventCard from "../components/events/EventCard";

const EventPage = () => {
  const [view, setView] = useState("grid");
  const [activeCategory, setActiveCategory] = useState("All");
  const [filters, setFilters] = useState({ topic: "All", language: "All" });
  const [loading, setLoading] = useState(false);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);

  const topics = ["Tech", "Entrepreneurship", "Data"];
  const languages = ["English", "French", "German"];
  const categories = ["All", "Workshop", "Talk", "Info Session", "Demo Day"];

  const events = Array.from({ length: 50 }, (_, i) => ({
    id: i + 1,
    title: `Event ${i + 1}`,
    category: categories[i % categories.length],
    description: `This is a description for Event ${i + 1}`,
    image: "https://via.placeholder.com/150",
    type: i % 2 === 0 ? "Online" : "On-site",
    language: i % 2 === 0 ? "English" : "French",
  }));

  useEffect(() => {
    // Show loader when filters change
    setLoading(true);
    const timeout = setTimeout(() => {
      const filtered =
        activeCategory === "All"
          ? events
          : events.filter((event) => event.category === activeCategory);

      const furtherFiltered = filtered.filter(
        (event) =>
          (filters.topic === "All" || event.type === filters.topic) &&
          (filters.language === "All" || event.language === filters.language)
      );

      setDisplayedEvents(furtherFiltered.slice(0, visibleCount));
      setLoading(false);
    }, 1000); // 1 second loader delay

    return () => clearTimeout(timeout);
  }, [filters, activeCategory, visibleCount]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setVisibleCount(12); // Reset visible count when filters change
  };

  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setVisibleCount((prev) => prev + 12);
      setLoading(false);
    }, 1000); // Simulate load more delay
  };

  return (
    <div className="container mx-auto px-4 py-10 mt-20 mb-20">
      {/* Header */}
      <div className="bg-blue-100 text-white py-8 px-6 mt-10 rounded-lg mb-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8">
          {/* Content Section */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <div className="relative inline-flex items-center px-4 py-2 bg-blue-100 rounded-full">
                <span className="absolute h-3 w-3 bg-blue-500 rounded-full animate-ping"></span>
                <span className="relative h-3 w-3 bg-blue-500 rounded-full mr-2"></span>
                <span className="text-blue-500 font-semibold text-sm">
                  LIVE EVENTS
                </span>
              </div>
            </div>
            <h1 className="text-3xl text-blue-500 lg:text-4xl font-bold mb-2">
              Explore Our Events
            </h1>
            <p className="text-lg text-blue-500">
              Discover workshops, talks, and demo days tailored to your
              interests.
            </p>
          </div>

          {/* Image Section */}
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1628348068343-c6a848d2b6dd?q=80&w=600&h=400&auto=format&fit=crop"
              alt="Live Events"
              className="rounded-lg shadow-lg object-cover w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        {/* Filters */}
        <EventFilter
          topics={topics}
          languages={languages}
          activeFilters={filters}
          onFilterChange={handleFilterChange}
        />

        {/* View Toggle */}
        <ViewToggle view={view} onViewChange={setView} />
      </div>

      {/* Category Tabs */}
      <CategoryTabs
        categories={categories}
        activeCategory={activeCategory}
        onCategoryChange={setActiveCategory}
      />

      {/* Events */}
      {loading ? (
        <div className="text-center py-10">
          <div className="loader border-t-4 border-blue-500 w-12 h-12 rounded-full animate-spin"></div>
          <p className="mt-4 text-gray-600">Loading events...</p>
        </div>
      ) : (
        <div
          className={`grid ${
            view === "grid"
              ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
              : "grid-cols-1 gap-4"
          }`}
        >
          {displayedEvents.map((event) => (
            <EventCard key={event.id} event={event} view={view} />
          ))}
        </div>
      )}

      {/* Load More Button */}
      {!loading && displayedEvents.length < events.length && (
        <div className="text-center mt-8">
          <button
            onClick={handleLoadMore}
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default EventPage;
