import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-blue-500 text-white py-4 px-6 fixed top-0 w-full z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <div className="flex items-center">
          <img
            src="/D1 (2).png" // Replace with the correct relative path to your logo
            alt="Blueblockss"
            className="h-12 w-auto"
          />
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex space-x-6">
          <Link
            to="/"
            className="hover:underline hover:text-blue-300 transition duration-200"
          >
            Home
          </Link>
          <Link
            to="enterprise"
            className="hover:underline hover:text-blue-300 transition duration-200"
          >
            Enterprise
          </Link>
          <Link
            to="events"
            className="hover:underline hover:text-blue-300 transition duration-200"
          >
            Events
          </Link>
          <Link
            to="talent"
            className="hover:underline hover:text-blue-300 transition duration-200"
          >
            Talent
          </Link>
          <Link
            to="about"
            className="hover:underline hover:text-blue-300 transition duration-200"
          >
            About
          </Link>
         
          <Link
            to="contact"
            className="hover:underline hover:text-blue-300 transition duration-200"
          >
            Contact
          </Link>
        
        </nav>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <nav className="lg:hidden bg-blue-500 shadow-lg">
          <ul className="space-y-4 px-6 py-4">
            <li>
              <Link
                to="/"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="talent"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Talent
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
